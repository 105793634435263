.avatar {
  background-color: $light;
  overflow: hidden;
}

.avatar-image {
  display: flex;
}

.avatar-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  span.tag {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    padding-top: 0.2rem;
    margin-top: 0.25rem;
    max-width: 100%;
  }
}

.profile-avatar-edit {
  cursor: pointer;
  display: block;
  .profile-avatar-edit-hover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $radius-rounded;
    background: rgba(#fff, 0.5);
    color: $primary;
    font-size: 48px;
    text-align: center;
    line-height: 128px;
  }
  &:hover {
    .profile-avatar-edit-hover {
      display: block;
    }
  }
  .profile-avatar-edit-progress {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    text-align: center;
    line-height: 128px;
    color: #fff;
  }
  &.is-loading {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius-rounded;
      background: rgba($primary, 0.5);
    }
    &:after {
      @include loader;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 3rem;
      width: 3rem;
      margin-top: -1.5rem;
      margin-left: -1.5rem;
    }
    .profile-avatar-edit-progress {
      display: block;
    }
  }
  &.has-error {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius-rounded;
      background: rgba($danger, 0.5);
      color: $white;
      font-size: 48px;
      text-align: center;
      line-height: 128px;
    }
  }
}
