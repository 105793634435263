@charset "utf-8";

@font-face {
  font-family: 'Brandon Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./public/assets/fonts/2E75FF_1_0.eot'); /* IE9 Compat Modes */
  src: local('BrandonRegular'), local('BrandonRegular'),
    url('./public/assets/fonts/2E75FF_1_0.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./public/assets/fonts/2E75FF_1_0.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./public/assets/fonts/2E75FF_1_0.woff')
      format('woff'),
    /* Modern Browsers */ url('./public/assets/fonts/2E75FF_1_0.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./public/assets/fonts/2E75FF_1_0.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Brandon Medium';
  font-style: normal;
  font-weight: 400;
  src: url('./public/assets/fonts/2E75FF_2_0.eot'); /* IE9 Compat Modes */
  src: local('BrandonMedium'), local('BrandonMedium'),
    url('./public/assets/fonts/2E75FF_2_0.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./public/assets/fonts/2E75FF_2_0.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./public/assets/fonts/2E75FF_2_0.woff')
      format('woff'),
    /* Modern Browsers */ url('./public/assets/fonts/2E75FF_2_0.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./public/assets/fonts/2E75FF_2_0.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Brandon Bold';
  font-style: normal;
  font-weight: 400;
  src: url('./public/assets/fonts/2E75FF_0_0.eot'); /* IE9 Compat Modes */
  src: local('BrandonBold'), local('BrandonBold'),
    url('./public/assets/fonts/2E75FF_0_0.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./public/assets/fonts/2E75FF_0_0.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./public/assets/fonts/2E75FF_0_0.woff')
      format('woff'),
    /* Modern Browsers */ url('./public/assets/fonts/2E75FF_0_0.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./public/assets/fonts/2E75FF_0_0.svg#OpenSans') format('svg'); /* Legacy iOS */
}

// Breakpoints
$mobile-small: 425px;
$tablet: 768px;
$massive: 1700px;
$shortscreen: 960px;

// Colours.
$white: #fff;
$green: #73b62a;
$green-alt: #7ec4a3;
$green-alt-dark: #2c7932;
$yellow: #ffd235;
$yellow-dark: darken($yellow, 30%);
$red: #eb5e62;
$pink: #f6babc;
$grey-dark: #3d4859;
$grey-mid: #999;
$cyan: #85cbf1;
$blue: #f3fafe;
$orange: #f39654;
$black: #000;
$success: #195f3e;

$colours: (
  'white': $white,
  'green': $green,
  'green-alt': $green-alt,
  'green-alt-dark': $green-alt-dark,
  'yellow': $yellow,
  'red': $red,
  'pink': $pink,
  'grey-dark': $grey-dark,
  'grey-mid': $grey-mid,
  'cyan': $cyan,
  'orange': $orange,
);

$primary: $green-alt-dark;
$link: $primary;
$link-hover: #000;

$react-modal-z: 1000;
$modal-z: 8000;
$navbar-fixed-z: 2500;
$chatroom-form-z: 2600;

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/helpers/_all.sass';

@mixin tiny {
  @media screen and (max-width: $mobile-small) and (max-height: 700px) {
    @content;
  }
}

@mixin massive {
  @media screen and (min-width: $massive) {
    @content;
  }
}

$container-offset: (2 * $gap + 80);
$tabs-toggle-link-active-background-color: $dark;
$tabs-toggle-link-active-border-color: $dark;
$modal-background-background-color: bulmaRgba($scheme-invert, 0.5);

$help-size: $size-normal;

@import '~bulma/sass/components/card.sass';
@import '~bulma/sass/components/media.sass';
@import '~bulma/sass/components/message.sass';
@import '~bulma/sass/components/modal.sass';
@import '~bulma/sass/components/navbar.sass';
@import '~bulma/sass/components/pagination.sass';
@import '~bulma/sass/components/tabs.sass';
@import '~bulma/sass/components/panel.sass';
@import '~bulma/sass/elements/_all.sass';
@import '~bulma/sass/form/_all.sass';
@import '~bulma/sass/grid/columns.sass';
@import '~bulma/sass/grid/tiles.sass';
@import '~bulma/sass/layout/hero.sass';
@import './node_modules/bulma-checkradio/dist/css/bulma-checkradio.sass';
@import './node_modules/bulma-switch/src/sass/index.sass';
@import '~@creativebulma/bulma-collapsible';
@import '~bulma/sass/components/dropdown.sass';

$font-brand: 'Brandon Regular', $family-sans-serif;
$font-brand-bold: 'Brandon Medium', $family-sans-serif;
$font-brand-extra-bold: 'Brandon Bold', $family-sans-serif;

$background: $white-ter;

$container-narrow-width: 650px;
$navbar-height-mobile: 60px;

@import './sass/_all';
