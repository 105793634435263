.postcard {
  margin-bottom: 1rem;
  border-radius: $radius;
  background: $white;

  .card-content {
    position: relative;
    @include mobile {
      padding: 1rem;
    }
  }

  a,
  button.button {
    position: relative;
    z-index: 1;
  }

  &.is-space:before {
    position: absolute;
    top: 5px;
    right: 5px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: $yellow;
  }
}

@include desktop {
  .postcard-reason {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.postcard-reason-content {
  font-family: $font-brand-bold;
  background-color: $yellow;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.5rem 1rem;
  border-radius: $radius $radius 0 0;
  @include desktop {
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    border-radius: $radius 0 $radius 0;
  }
}

.has-preview .postcard-reason-content {
  border-radius: 0;
  @include desktop {
    border-radius: 0 0 $radius 0;
  }
}

.postcard-author {
  margin-bottom: 0.5rem !important;
  a {
    color: $dark;
  }
  .author-mini-bio,
  time {
    font-size: 0.9rem;
  }

  .postcard-author-username {
    display: inline-flex;
    align-items: center;
    button.button {
      padding: 0;
      height: auto;
      position: relative;
      top: -2px;
    }
  }

  .postcard-author-content {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  .author-mini-bio {
    color: $grey;
    @include tablet {
      line-height: 1.2em;
      margin-top: 0.25rem;
    }
  }
  .postcard-date-last-active {
    display: block;
    color: $grey;
    font-size: 0.9rem;
    @include tablet {
      display: inline-block;
      margin-left: 1rem;
    }
  }
}

.postcard-body {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .title {
    a {
      position: static;
      color: $dark;
    }
  }

  .postcard-body-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .postcard-content {
    .content {
      font-family: $font-brand;
      overflow: hidden;

      .postcard-content-body {
        font-size: 1.1rem;
        white-space: pre-wrap;
        @include tablet {
          font-size: 1.2rem;
        }

        a {
          text-decoration: underline;
          font-family: $font-brand-bold;
        }
      }
    }
    margin-right: 1rem;
    margin-bottom: 1rem;

    @include mobile {
      margin-right: 0;
    }
  }
  .postcard-actions {
    width: 64px;
    flex-shrink: 0;
    margin-left: auto;
    a,
    button {
      color: $dark;
      position: static;
    }
    .control {
      position: relative;
      display: flex;
      justify-content: center;
      .button-count {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 2;
      }
      .icon svg {
        @include tablet {
          height: 2rem !important;
          width: 2rem !important;
          font-size: 1.25rem;
        }
      }
    }

    @include mobile {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-left: 0;
      width: auto;
    }
  }

  .postcard-categories,
  .postcard-location {
    align-items: center;
    .media-left {
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
    }
    ul {
      max-height: 4.5rem;
      overflow: hidden;
    }
  }

  .postcard-categories .media-left {
    margin-bottom: 0.25rem;
  }
}

@include mobile {
  .authenticated .postcard-body .postcard-actions {
    justify-content: space-between;
  }
}

// Make whole postcard clickable when in a post list
.posts .postcard .postcard-body .title a {
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.postcard-video {
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.chatroom-url-preview,
.postcard-url-preview {
  border: 1px solid $grey-lighter;
  border-radius: $radius;
  background-color: #fff;
  a {
    display: flex;
    align-content: flex-start;
    color: $text;
    height: 100px;
    overflow: hidden;

    @include tablet {
      height: 200px;
    }
  }
  .preview-url-image {
    border-right: 1px solid $grey-lighter;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 100px;
    flex-shrink: 0;
    border-radius: $radius-large 0 0 $radius-large;

    @include tablet {
      height: 200px;
      width: 200px;
    }
  }
  .preview-url-content {
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      padding: 1rem 1rem 0.5rem 1rem;
    }
    h4 {
      font-family: $family-sans-serif;
      font-weight: bold;
    }
    p {
      display: none;
      color: $grey;

      @include tablet {
        display: block;
      }
    }
  }
  .preview-url-website {
    color: $grey;
    background-color: #fff;
    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin-right: 0.25rem;
    }
  }
}

.shiftms-url-preview {
  a {
    display: block;
    color: $white;
    &:hover {
      color: $white;
    }
    .shiftms-url-image {
      display: block;
      background-color: $light;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      > div {
        min-height: 200px;
      }
    }
    .shiftms-url-content {
      padding: 1rem;
      background-color: $green;
      font-family: $font-brand-bold;
    }
  }
}

.shiftms-url-image {
  background-color: $grey-dark;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: $radius $radius 0 0;

  > div {
    min-height: 200px;
  }
}

.postcard .postcard-categories,
.postcard .postcard-location {
  margin-bottom: 0 !important;
  border-top: none;
  margin-top: 0.5rem;
  padding-top: 0;
}

.postcard .postcard-categories {
  ul li {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
  }
}

.postcard a.postcard-support-prompt {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  min-width: auto;
  max-width: none;
  white-space: nowrap;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  @include tablet {
    top: -2.5rem;
    bottom: auto;
    min-width: 120px;
    max-width: 140px;
    white-space: normal;
  }
}

.postcard a.postcard-comment-prompt {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  max-width: 200px;
  min-width: 200px;
  @include tablet {
    right: 60px;
    left: auto;
    transform: translateY(30px);
    &::after {
      left: auto;
      right: -1rem;
      top: auto;
      bottom: 0.25rem;
      margin-left: 0;
      border-color: transparent transparent transparent $info;
    }
  }
  @include widescreen {
    left: 60px;
    right: auto;
    top: 0;
    bottom: auto;
    transform: none;
    &::after {
      left: -1rem;
      right: auto;
      top: 15px;
      bottom: auto;
      border-color: transparent $info transparent transparent;
    }
  }
}

.reactionCount {
  position: absolute;
  left: 0.5rem;
  top: 2.3rem;
}

.comment a.postcard-comment-support-prompt {
  position: absolute;
  bottom: auto;
  top: 40px;
  left: 0;
  transform: none;
  z-index: 3;
  min-width: auto;
  max-width: none;
  white-space: nowrap;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  &::after {
    top: auto;
    bottom: 100%;
    left: 0.6rem;
    margin-left: 0;
    border-color: transparent transparent $info transparent;
  }

  @include tablet {
    bottom: 40px;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      top: 100%;
      bottom: auto;
      left: 50%;
      right: auto;
      margin-left: -0.5rem;
      border-color: $info transparent transparent transparent;
    }
  }

  @include widescreen {
    min-width: 110px;
    white-space: normal;
  }
}
