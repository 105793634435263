.is-tooltip {
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    bottom: auto;
    left: 50%;
    right: auto;
    margin-left: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: $light transparent transparent transparent;
  }

  &.is-danger::after {
    border-color: $danger transparent transparent transparent;
  }
  &.is-primary::after {
    border-color: $primary transparent transparent transparent;
  }
  &.is-info::after {
    border-color: $info transparent transparent transparent;
  }
}

.notification.is-tooltip {
  padding: 0.25rem;
  max-width: 140px;
  min-width: 120px;
  text-align: center;
  line-height: 0.9rem;
  font-size: 0.75rem;
  border: 2px solid white;
  display: block;
  .tooltip-label {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}

div[data-radix-popper-content-wrapper] {
  z-index: 2 !important;
}

.popover-content {
  border-radius: $radius-large;
  box-shadow: $shadow;
  padding: 1rem;
  width: 200px;
  background-color: $info;
  .buttons {
    margin-bottom: 0;
    margin-top: 0.5rem;
    .button:last-child {
      margin-bottom: 0;
    }
  }
  p.is-font-brand {
    line-height: 1.2;
  }
}

.popover-arrow {
  fill: $info;
}

.popover-close.button.is-rounded {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 1.35rem;
}

.comment-reactions .popover-anchor,
.postcard-actions .popover-anchor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
