.share-buttons {
  padding-top: 1rem;
}

.social-link-facebook,
.share-button-facebook {
  color: #4367b0;
}

.social-link-twitter,
.share-button-twitter {
  color: #26a1ef;
}

.social-link-linkedin,
.share-button-linkedin {
  color: #0b77b3;
}

.social-link-youtube {
  color: #ff0000;
}

.social-link-instagram {
  color: #e4405f;
}
