.page-container.profile {
  display: block;
}

.profile-header {
  .profile-image {
    .image {
      margin: 0 auto;
    }
    .profile-avatar-prompt {
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0.5rem;
      &::after {
        top: -0.5rem;
        border-color: transparent transparent $primary transparent;
      }
    }
  }
  .profile-name,
  .profile-stats {
    text-align: center;
  }
  .profile-name {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .profile-stats {
    font-family: $font-brand;
    font-size: 1.2rem;
  }
  .profile-header-buttons {
    margin-top: 1rem;
    display: flex;
    flex-basis: 50%;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    .button {
      margin: 0 0.25rem;
    }
  }
  .follow-buttons {
    display: flex;
    .follow-button {
      margin-right: 0.5rem;
    }
    .inbox-button {
      margin-left: 0.5rem;
    }
  }
  .profile-header-badge {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 20px;
    width: 128px;
  }
}

.profile-tabs {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  li .tag {
    margin-left: 0.5rem;
  }
}

.profile-about-section {
  background-color: $white;
  border: 1px solid $light;
  border-radius: $radius;
  padding: 1rem 0;
  dl {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $light;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    dt {
      text-transform: uppercase;
      font-weight: bold;
      color: $grey;
      font-size: 0.8rem;
      padding: 0 1rem;
    }
    dd {
      color: $text;
      padding: 0 1rem;
      white-space: pre-wrap;
    }
  }
  .profile-details .media-content {
    overflow-x: visible;
  }
  @include tablet {
    dl {
      display: flex;
      flex-direction: row;
      dt {
        width: 40%;
        flex-grow: 0;
        flex-shrink: 0;
      }
      dd {
        flex-grow: 1;
      }
    }
  }
}

.profile-edit {
  margin-top: 2rem;
}

.rank-preferences-number {
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 0.75rem;
}

.rank-preferences-item {
  width: 100%;
  color: $text;
  &.rank-preferences-item-1 {
    background-color: #7ec4a3;
  }
  &.rank-preferences-item-2 {
    background-color: #9ec888;
  }
  &.rank-preferences-item-3 {
    background-color: #bfcb6c;
  }
  &.rank-preferences-item-4 {
    background-color: #ffd235;
  }
}

.panel-block:first-child .rank-preferences-item {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.panel-block:last-child .rank-preferences-item {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
