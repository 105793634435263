.post-form {
  padding-top: 1rem;
  @include tablet {
    padding-top: 2rem;
  }

  form {
    background-color: $white;
    padding: 0.5rem;
    @include tablet {
      padding: 1rem 1rem 0.5rem 1rem;
    }
  }

  &.is-active {
    border-color: $yellow;
  }

  .media {
    flex-direction: row-reverse;
    .media-left {
      margin-right: 0;
      margin-left: 1rem;
    }
  }
  .preview-url {
    margin-bottom: 1em;
  }
  .post-create-options {
    .icon {
      margin-right: 0.5rem;
    }
  }
  .post-form-footer {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    .post-form-submit {
      justify-content: flex-end;
      .cancel-control {
        margin-right: auto;
      }
    }
  }

  label {
    display: none;
  }
  .algolia-places {
    .ap-input-icon.ap-icon-pin {
      display: none;
    }
  }
}

.post-create-form {
  border: 6px solid lighten($yellow, 10%);
  border-radius: $radius-large;
  margin-bottom: 1rem;
  .post-form {
    padding-top: 0;
  }
}

#stream .post-create-form .post-form:not(.is-active) .help {
  display: none;
}

#stream .post-create-form .post-form:not(.is-active) .media-content > .field {
  margin-bottom: 0;
}

#stream .post-create-form .post-form:not(.is-active) .post-form-title-input {
  border-color: transparent;
  box-shadow: none;
}

#stream .post-form .post-form-collapsable {
  display: none;
  max-width: 526px;
}

.post-edit .post-form .post-form-collapsable {
  max-width: 538px;
}

#stream .post-form.is-active .post-form-collapsable {
  display: block;
  max-width: 526px;
}

// IN MODAL VERSION
.post-create-dialog {
  border: 5px solid $yellow;
  justify-content: flex-start;

  @include desktop {
    justify-content: center;
  }

  .modal-header {
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 0;
  }

  .buttons.modal-close-button {
    margin-bottom: 0;
  }

  .post-create-form {
    border: none;
    margin-bottom: 0;

    .post-form .post-form-collapsable {
      display: block;
      max-width: 478px;
    }
  }


}

// .grid-control {
//   display: grid;
//   grid-template-columns: 100% 1% 1%;
//   column-gap: 23px;
//   align-items: center;
// }

.post-create-form-placeholder {
  border: 6px solid lighten($yellow, 10%);
  border-radius: $radius;
  background-color: lighten($yellow, 25%);
  padding: 0.5rem 1rem;
  font-family: $font-brand-extra-bold;
  // font-weight: bold;
  color: $grey-dark;
  display: block;
  margin-bottom: 1rem;

  font-size: 1.5rem;
  @include tablet {
    font-size: 2rem;
  }

  .media {
    flex-direction: row-reverse;
    align-items: center;
    .media-content {
      display: flex;
      align-items: center;
    }
    .media-left {
      margin-right: 0;
      margin-left: 1rem;
    }
    .icon {
      @include tablet {
        margin-right: 1rem;
      }
    }
    .post-create-form-placeholder-text {
      margin-top: 0.4rem;
      line-height: 1;
    }
  }
  &:hover {
    background-color: lighten($yellow, 20%);
  }
}