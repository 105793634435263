.findmsers-filters {
  .range-slider-wrapper {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-top: 2.5rem;
  }
  .MuiSlider-root {
    color: $grey-mid;
  }

  .MuiSlider-rail,
  .MuiSlider-track {
    height: 6px;
  }

  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    margin-top: -7px;
    background-color: $dark;
  }

  .MuiSlider-valueLabel {
    left: calc(-50% + 4px);
  }
}
