.inbox {
  padding-bottom: 1rem;
  @include tablet {
    padding-bottom: 2rem;
  }
}
.inbox-list {
  padding-top: 1rem;
  li {
    margin-bottom: 0.5rem;
    .inbox-list-item-link {
      display: block;
      padding: 1rem;
      color: $text;
      //background-color: $white;
      //border: 1px solid $grey-lighter;
      border-radius: $radius;
      &:hover {
        background-color: $light;
      }
      .media {
        align-items: center;
        align-items: flex-start;
      }
      .media-left {
        position: relative;
        @include mobile {
          .is-64x64 {
            width: 36px;
            height: 36px;
          }
        }
      }
      .media-left .image {
        overflow: hidden;
        background-color: $light;
        border-radius: $radius-rounded;
        color: $light;
      }
    }
    .inbox-list-item-link.is-not-read {
      .media-left:after {
        position: absolute;
        content: '';
        width: 64px;
        height: 64px;
        @include mobile {
          width: 36px;
          height: 36px;
        }
        background-color: transparent;
        border: 4px solid $primary;
        top: 0;
        left: 0;
        border-radius: $radius-rounded;
      }
    }
    .inbox-list-item-chatroom-name {
      font-family: $font-brand-bold;
      font-size: 1.2rem;
      @include tablet {
        font-size: 1.4rem;
      }
    }
    .inbox-list-item-message {
      font-family: $font-brand;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      word-wrap: break-word;
      max-width: 552px;
      color: $grey-dark;
      @include tablet {
        font-size: 1.4rem;
      }
    }
    .inbox-list-item-link.is-not-read .inbox-list-item-message {
      font-family: $font-brand-bold;
    }
    .inbox-list-item-date {
      color: $grey;
      font-size: 0.9rem;
      //margin-top: 0.25rem;
    }
  }
}
