.mainmenu-overlay {
  background-color: rgba(#000, 0.5);
  z-index: 3000;
  position: fixed;
  display: none;
  &.is-active {
    display: block;
  }
}

.mainmenu {
  position: fixed;
  right: 0;
  width: 80%;
  max-width: 360px;
  background-color: $white;
  bottom: 0;
  top: 0;
  z-index: 3050;
  transition: all 0.25s;
  transform: translateX(100%);
  overflow: scroll;
  display: flex;
  &.is-active {
    transform: translateX(0);
    box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
      0 0px 0 1px rgba($scheme-invert, 0.02);
  }
}

.mainmenu-close {
  position: fixed;
  top: 0;
  right: 0;
}

.mainmenu-content {
  color: $grey;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  max-width: 100%;
  .mainmenu-section {
    border-top: 1px solid $light;
    padding: 0.5rem 0;
    h3,
    .control {
      padding: 0.5rem 2rem;
      margin: 0;
      .media {
        align-items: center;
        .media-content {
          font-family: $font-brand-bold;
          font-size: 1.4rem;
        }
      }
    }
    ul li {
      font-family: $font-brand;
      font-size: 1.5rem;
      a {
        display: block;
        padding: 0.5rem 2rem;
        color: $text;
        &:hover {
          color: $black-ter;
        }
      }
    }
    ul li.mainmenu-section-item-bold {
      font-family: $font-brand-bold;
    }
  }
  .mainmenu-section:first-child {
    border-top: none;
  }
  .mainmenu-social-links {
    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mainmenu-social-link {
        margin-right: 1rem;
        font-size: 2rem;
      }
    }
  }
  .mainmenu-footer {
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $grey-dark;
    color: $white;
    .mainmenu-footer-content {
      padding: 1rem 2rem;
      font-family: $font-brand-bold;
      font-size: 0.9rem;
      a {
        color: #fff;
        font-family: $font-brand-extra-bold;
        text-decoration: underline;
      }
      p {
        margin-bottom: 1em;
      }
    }
  }
}
