h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: $font-brand-bold;
  font-weight: normal;
}

.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .title {
    font-weight: normal;
  }
}
