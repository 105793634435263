.signin-prompt {
  background-color: $green;
  color: $white;
  z-index: $navbar-z;
  padding: 1rem 0;

  h1.title {
    margin-bottom: 0.5rem;
    color: $white;
  }

  p {
    font-size: 0.9rem;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  @include desktop-only {
    .container.is-narrow {
      max-width: $desktop - $container-offset;
    }
  }

  @include widescreen {
    border-radius: $radius;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
      0 0px 0 1px rgba($scheme-invert, 0.02);
  }
}

.auth-dialog {
  border-radius: $radius;
}

.signin-page,
.join-page,
.username-page {
  padding-top: 1rem;

  @include mobile {
    padding-top: 0;

    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .signin-other {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @include tablet {
    padding-top: 2rem;

    .form-buttons {
      max-width: 460px;
      margin: 0 auto;
    }
  }

  .signin-other {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .magiclink-prompt {
    display: flex;
    flex-direction: row;
    .magiclink-prompt-content {
      p {
        margin-bottom: 0.25rem;
        line-height: 1.5rem;
      }
      margin-bottom: 1rem;

      @include tablet {
        display: flex;
        flex-direction: row;
        margin-right: 1rem;
      }
    }
  }
}

.signin-form,
.join-form,
.username-form {
  background: $grey-dark;
  padding: 2rem 1rem 1rem 1rem;

  @include tablet {
    padding: 2rem 2rem 1rem 2rem;
    border-radius: $radius-large;
  }
  color: #fff;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    color: #fff;
  }
  @include tablet {
    h1 {
      font-size: 2.25rem;
      margin-bottom: 2rem;
    }
  }
  .field .field {
    margin-bottom: 0;
  }
  a:not(.button) {
    color: $white;
    text-decoration: underline;
    font-family: $font-brand-bold;
  }
}

.hello-tickboxes {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.signin-banner {
  color: $light;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  transform: translateY(22rem);
  transition: transform 300ms ease-in-out;

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:focus {
      outline: none !important;
    }
  }

  .signin-banner-content {
    background-color: $grey-dark;
    padding: 2rem 0;
    position: relative;
    z-index: 2005;
    height: 22rem;
  }

  .signin-banner-handle {
    background-color: transparent;
    display: block;
    position: relative;

    .signin-banner-image {
      background-image: url('../../public/assets/images/banner-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: transparent;
      height: auto;
      padding-top: 62.5%; // 16:10
      transition: transform 300ms ease-in-out;
      position: relative;
      z-index: 2001;

      @include tablet {
        padding-top: 16.541%; // 63:17
        background-image: url('../../public/assets/images/banner-signin.png');
      }
    }

    .signin-banner-handle-text {
      display: flex;
      background-color: #fbe76b;
      height: 5rem;
      align-items: center;
      text-decoration: none !important;
      position: absolute;
      z-index: 2006;
      bottom: 0;
      left: 0;
      right: 0;

      @include tablet {
        background-color: transparent;
      }
    }

    p {
      font-family: $font-brand-extra-bold;
      color: $text;
      font-size: 1.6rem;
      line-height: 1.1;
      @include touch {
        padding-right: 3rem;
      }
    }

    .icon {
      height: 3rem;
      width: 3rem;
      color: $text;
    }
  }

  &.is-active {
    transform: translateY(0);
    .signin-banner-handle {
      height: 5rem;
    }
    .signin-banner-handle-text {
      background-color: #fbe76b;
    }
    .signin-banner-image {
      transform: translateY(50rem);
    }
  }

  h1 {
    margin-bottom: 0.5em;
    font-size: 2rem;
    line-height: 1em;
    padding-right: 2rem;
  }
  a:not(.button) {
    color: $white;
    text-decoration: underline;
  }
  .delete {
    position: absolute;
    top: 1rem;
    right: 1rem;
    &.is-large {
      height: 48px;
      max-height: 48px;
      max-width: 48px;
      min-height: 48px;
      min-width: 48px;
      width: 48px;
    }
  }
  .notification {
    font-size: 1rem;
  }

  @include desktop {
    font-size: 1.4rem;
    h1 {
      font-size: 2.5rem;
    }
  }
}

.auth-message {
  border: 3px solid transparent;
  font-weight: bold;

  .delete {
    top: 1.25rem;
    right: 1rem;
  }

  &.is-danger {
    border-color: $danger;
  }

  &.is-success {
    border-color: $success;

    &.is-light {
      border-color: $green-alt;
      color: #185d40;
    }
  }
}

.onboarding-link-hero {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  overflow: scroll;
  h1 {
    font-size: 2.4rem;
    font-family: $font-brand-extra-bold;
  }
  p {
    font-size: 2rem;
    line-height: 1.1;
  }
  .onboarding-link-hero-container {
    max-width: $container-narrow-width;
    margin: 0 auto;
    @include desktop {
      height: 100vh;
      display: flex;
      max-width: $desktop;
      align-items: center;
      justify-content: center;
    }
  }
  .onboarding-link-hero-image {
    svg {
      max-width: 100%;
      height: auto;
    }
    @include tiny {
      svg {
        max-height: 160px;
      }
    }
    @include desktop {
      padding-right: 40px;
    }
  }
  .onboarding-link-hero-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;

    .has-background-unset {
      background-color: unset;
    }

    @include tablet {
      position: static;
      padding: 2rem 0;
    }
  }
}

.onboarding-link-hero.is-dark {
  background-color: $grey-dark;
  color: $white;
  h1 {
    color: $white;
  }
}

form.auth-form {
  .input {
    border-width: 2px;
  }
  .label {
    margin-bottom: 0.25rem;
  }
  .field .help {
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
  .password-strength-bar > div > div {
    height: 4px !important;
  }
}

.form-error {
  display: inline-flex;
  padding-top: 0.5rem;
  span {
    margin-right: 0.5rem;
  }
}
