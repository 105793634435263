.colour-picker {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    .button {
      border-color: transparent;
      border-width: 2px;
      &:hover,
      &:focus,
      &:active {
        color: inherit;
      }
    }
  }
  li.selected {
    .button {
      border-color: $primary;
    }
  }
}

@mixin colour-variations {
  @each $name, $hex in $colours {
    &-#{$name} {
      background-color: $hex !important;
      color: findColorInvert($hex) !important;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .title {
        color: findColorInvert($hex);
      }
      a {
        color: findColorInvert($hex);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.colour {
  @include colour-variations;
}
