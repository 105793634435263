.inbox-chatroom-message {
  clear: both;
  float: left;
  padding-bottom: 1rem;
  max-width: 100%;

  .comment-avatar {
    a {
      display: block;

      &:after {
        content: ' ';
        position: absolute;
        left: 100%;
        top: 50%;
        width: 0;
        height: 0;
        pointer-events: none;
        border: 4px solid transparent;
        border-top: 4px solid $grey-dark;
        border-right: 4px solid $grey-dark;
        transform: rotate(45deg);
        margin-top: -4px;
      }
    }
  }
  .ChatroomLinkified {
    color: white;
    text-decoration: underline;
    font-family: 'Brandon Medium', BlinkMacSystemFont, -apple-system, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  }
  .ChatroomLinkified:hover {
    color: #000;
  }
  .media {
    margin-bottom: 0.25rem;
  }

  @include touch {
    .media-content {
      overflow-x: visible;
    }
  }
  @include tablet {
    max-width: 80%;
    padding-bottom: 1rem;
    .comment-avatar.is-32x32 {
      width: 48px;
      height: 48px;
    }
  }
  .inbox-chatroom-message-author {
    color: $dark;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    float: left;
    @include tablet {
      margin-bottom: 0.5rem;
      margin-top: 0.75rem;
    }
    small {
      margin-left: 0.5rem;
    }
  }
  .inbox-chatroom-message-date {
    font-size: 0.8em;
    clear: left;
  }
  .inbox-chatroom-message-content {
    position: relative;
    clear: left;
    background: $grey-dark;
    color: $white;
    border-radius: 4px;
    padding: 0.25em 0.75em;
    float: left;
    margin-bottom: 0.25rem !important;
    font-family: $font-brand;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-wrap: break-word;
    max-width: 100%;

    @include tablet {
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      margin-left: calc(48px + 1rem);
    }

    &.comment-author-blocked {
      background: $grey-light;
      font-style: italic;
    }
    &.comment-author-blocked:after {
      border-right-color: $grey-light;
    }
  }
  .inbox-chatroom-video {
    position: relative;
    margin-bottom: 0.25rem;
    max-width: 100%;

    @include tablet {
      margin-left: calc(48px + 1rem);
    }
  }

  .inbox-chatroom-message-meta {
    @include tablet {
      margin-left: calc(48px + 1rem);
    }
  }
  &.inbox-chatroom-message-sent {
    float: right;

    .comment-avatar a:after {
      left: -8px;
      transform: rotate(-135deg);
      border-top-color: $grey-dark;
      border-right-color: $grey-dark;
    }

    .media-left {
      -webkit-order: 3;
      -ms-flex-order: 3;
      order: 3;
      margin-right: 0;
      margin-left: 1rem;
    }
    .inbox-chatroom-message-author {
      float: right;
    }
    .inbox-chatroom-message-meta {
      float: right;
      clear: right;
      @include tablet {
        margin-left: 0;
        margin-right: calc(48px + 1rem);
      }
    }
    .inbox-chatroom-message-date {
      text-align: right;
      clear: right;
    }
    .inbox-chatroom-message-content {
      background: $grey-dark;
      float: right;
      color: $white;
      clear: right;

      @include tablet {
        margin-left: 0;
        margin-right: calc(48px + 1rem);
      }
    }
    .inbox-chatroom-video {
      @include tablet {
        margin-left: 0;
        margin-right: calc(48px + 1rem);
      }
    }
  }
  &.comment-deleted {
    .comment-content {
      color: $grey;
      background: $light;
      &:after {
        display: none;
      }
    }
  }
}
