.hashtags-list {
  margin-top: 2rem;
  padding: 0 2rem;
  li {
    margin-bottom: 1rem;
    ul {
      display: none;
      margin-top: 1rem;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin-bottom: 0.5rem;
        flex: 0 1 auto;
        padding: 0 0.5rem;
      }
    }
    &.is-active ul {
      display: flex;
    }
  }
  .button {
    border-color: transparent;
  }
}

@include widescreen {
  .hashtags-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 1rem;
    li {
      flex: 0 1 33%;
      padding: 0 1rem;
      ul {
        width: calc(300% + 4rem);
      }
      li {
        padding: 0;
      }
      &:nth-child(3n + 2) ul {
        margin-left: calc(-100% - 2rem);
      }
      &:nth-child(3n + 3) ul {
        margin-left: calc(-200% - 4rem);
      }
    }
  }
}

.hashtags-trending {
  background-color: $light;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  overflow: hidden;

  h2 {
    padding-left: 2rem;
    margin-bottom: 0.5rem !important;
  }

  ul li a {
    border-color: transparent;
  }

  @include widescreen {
    display: flex;
    padding-bottom: 0.5rem;
    h2 {
      padding-top: 0.25rem;
      white-space: nowrap;
      margin-right: 1rem;
    }
  }
}
