.welcome-tour {
  background: rgba($dark, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2600;
  .welcome-tour-slides {
    position: fixed;
    bottom: calc(#{$navbar-height-mobile} + 0.5rem);
    left: 0.5rem;
    right: 0.5rem;
    top: calc(#{$navbar-height-mobile} + 0.25rem);
    background-color: $white;
    border-radius: $radius;
    padding: 1rem;
    z-index: 70;

    @include tablet {
      width: 450px;
      height: 450px;
      left: 50%;
      right: auto;
      top: 50%;
      bottom: auto;
      margin-left: -225px;
      margin-top: -225px;
    }

    @include desktop {
      width: 500px;
      height: 500px;
    }
  }
  .welcome-tour-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;

    .welcome-tour-slide-content {
      position: absolute;
      width: 100%;
      height: 100%;
      .welcome-tour-slide-content-pane {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      .welcome-tour-slide-content-icon {
        display: none;
        @include tablet {
          display: flex;
        }
        position: absolute;
        top: 14rem;
        left: 3rem;
        background: #fff;
        border-radius: 9999em;
        width: 80px;
        height: 80px;
        line-height: 80px;
        border: 3px solid $grey-dark;
        text-align: center;
        justify-content: center;
        span.icon {
          width: auto;
          height: auto;
        }
      }
      .welcome-tour-icon {
        position: fixed;
        z-index: 110;
        padding: 0.25rem;
        .welcome-tour-icon-item {
          background: #fff;
          border-radius: $radius;
          width: calc(20vw - 0.25rem);
          height: calc(#{$navbar-height-mobile} - 0.5rem);
          display: flex;
          justify-content: center;
          align-items: center;
          @include widescreen {
            width: calc(80px - 1rem);
            height: calc(80px - 1rem);
          }
          @include fullhd {
            border-radius: $radius-rounded;
          }
          .icon,
          .icon svg {
            width: 1.75rem !important;
            height: 1.75rem !important;
            @include widescreen {
              width: 2rem !important;
              height: 2rem !important;
            }
          }
        }
        &.welcome-tour-icon-stream {
          bottom: 0;
          left: 0;
          @include widescreen {
            bottom: auto;
            left: 50%;
            top: calc(#{$navbar-height-mobile} + 2rem);
            margin-left: calc(-#{$widescreen} / 2 - 0.75rem);
          }
          @include fullhd {
            margin-left: calc(-#{$fullhd} / 2 + 4.75rem);
          }
        }
        &.welcome-tour-icon-notifications {
          bottom: 0;
          left: 20%;
          @include widescreen {
            bottom: auto;
            left: 50%;
            top: calc(#{$navbar-height-mobile} + 2.75rem + 64px);
            margin-left: calc(-#{$widescreen} / 2 - 0.75rem);
          }
          @include fullhd {
            margin-left: calc(-#{$fullhd} / 2 + 4.75rem);
          }
        }
        &.welcome-tour-icon-post {
          bottom: 0;
          left: 40%;
          @include widescreen {
            bottom: auto;
            left: 50%;
            top: calc(#{$navbar-height-mobile} + 3.25rem + 128px);
            margin-left: calc(-#{$widescreen} / 2 - 0.75rem);
          }
          @include fullhd {
            margin-left: calc(-#{$fullhd} / 2 + 4.75rem);
          }
          .welcome-tour-icon-item {
            background: $yellow;
          }
        }
        &.welcome-tour-icon-inbox {
          bottom: 0;
          left: 60%;
          @include widescreen {
            bottom: auto;
            left: 50%;
            top: calc(#{$navbar-height-mobile} + 3.75rem + 194px);
            margin-left: calc(-#{$widescreen} / 2 - 0.75rem);
          }
          @include fullhd {
            margin-left: calc(-#{$fullhd} / 2 + 4.75rem);
          }
        }
        &.welcome-tour-icon-explore {
          bottom: auto;
          left: auto;
          top: 0;
          right: 52px;
          padding: 0.25rem 0;
          @include tablet {
            right: 92px;
          }
          @include widescreen {
            right: auto;
            left: 50%;
            margin-left: calc(#{$widescreen} / 2 - #{$container-offset} - 92px);
          }
          @include fullhd {
            margin-left: calc(#{$fullhd} / 2 - #{$container-offset} - 92px);
          }
          .welcome-tour-icon-item {
            height: calc(#{$navbar-height-mobile} - 0.75rem);
            width: 52px;
            .icon,
            .icon svg {
              width: 2.099999rem !important;
              height: 2.099999rem !important;
            }
          }
        }
      }
    }
    #welcome-tour-slide-content-post.welcome-tour-slide-content {
      .welcome-tour-slide-content-icon {
        background: $yellow;
      }
    }
    figure.image {
      position: absolute;
      top: 3rem;
      left: 0;
      right: 0;
      bottom: 8rem;
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        min-width: 0 !important;
        width: auto !important;
        margin: 0 auto;
        
        @media screen and (max-width: $mobile-small) {
        min-height: unset !important;
        height: auto !important;
      }
      }
    }
    h3 {
      position: absolute;
      bottom: 2.5rem;
      left: 0.5rem;
      right: 0.5rem;
      line-height: 1.25em;
      text-align: center;
      padding: 0.25rem 0 0 0;
      margin: 0;
      background: #fff;
      @include tablet {
        bottom: 3rem;
        left: 1.5rem;
        right: 1.5rem;
      }
    }
    .slide-button {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-weight: bold;
    }
  }
  .welcome-tour-skip {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 20;
    font-weight: bold;
  }
  .welcome-tour-dots {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    display: flex;
    li {
      position: relative;
      display: block;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        &:before {
          content: '';
          position: absolute;
          top: 0.625rem;
          left: 0.625rem;
          width: 0.75rem;
          height: 0.75rem;
          text-align: center;
          background-color: $dark;
          border-radius: $radius-rounded;
          opacity: 0.25;
        }
      }
    }
    li.is-active {
      button:before {
        opacity: 1;
      }
    }
  }
}
