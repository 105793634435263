.fbsignin-page {
  .hero {
    background-image: url('/assets/images/hero-background.png');
    background-repeat: repeat;
    background-color: $grey-dark;
    color: #fff;

    @include mobile {
      .hero-body {
        padding: 1.5rem;
        .content {
          margin-bottom: 0;
        }
      }
    }
    .title {
      color: #fff;
      font-family: $font-brand-extra-bold;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }

    &.is-fullheight {
      min-height: calc(100vh - 56px);
      @include tablet {
        min-height: calc(100vh - 56px);
      }
      @include desktop {
        min-height: calc(100vh - 105px);
      }
    }
  }

  blockquote {
    .blockquote-content {
      position: relative;
      background-color: $warning;
      font-family: $font-brand-bold;
      color: $text;
      padding: 1rem;
      border-radius: $radius;
      font-size: 1.2rem;
      @include widescreen {
        margin-right: 200px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 1.5rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.75rem 0.5rem 0 0.5rem;
        border-color: #ffd335 transparent transparent transparent;
      }
    }
    cite {
      font-style: normal;
      color: $warning;
      font-family: $font-brand-bold;
    }
    .media {
      margin-top: 1rem;
      margin-left: 0.5rem;
      align-items: center;
    }
  }
}
.company-details {
  background-color: #fff;
  font-family: $font-brand;

  .company-detail {
    padding: 0.5rem 1rem;
    text-align: center;
  }

  @include desktop {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .container {
      display: flex;
      justify-content: space-around;
      .company-detail {
        padding: 1rem 2rem;
      }
    }
  }
}
