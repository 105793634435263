.settings-list {
  background-color: $white;
  li {
    border-bottom: 1px solid $light;
    padding: 1rem;
    .field {
      label {
        display: block;
      }
    }
  }
}

.control.is-grouped .select:not(:last-child) {
  margin-right: 0.25rem;
}

.onboarding .settings-list {
  border-top: 1px solid $light;
  margin: 1rem 0;
  li {
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
    font-weight: bold;
  }
}
