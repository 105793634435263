.notifications {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include tablet {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.notifications-item {
  margin-bottom: 1rem;
  clear: left;
  border-bottom: 3px solid darken($white-bis, 10%);
  @include tablet {
    margin-left: calc(1rem + 48px);
  }
  .notification-avatars {
    position: relative;
    padding-left: calc(1rem + 48px);
    min-height: 48px;
    @include tablet {
      padding-left: 0;
    }
  }
  .notification-icon {
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    font-size: 28px;
    line-height: 48px;
    width: 48px;
    height: 48px;
    text-align: center;
    background-color: $dark;
    border-radius: $radius-rounded;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet {
      left: calc(-48px - 1rem);
    }
  }
}

.activity .notifications-item {
  border-bottom: 0;
  margin-bottom: 0;
}

.notifications-item-comment {
  .notification-icon {
    background-color: $yellow;
  }
}

.notifications-item-follow {
  .notification-icon {
    background-color: $green-alt;
  }
}

.notifications-item-post {
  .notification-icon {
    background-color: $yellow;
  }
}

.notifications-item-reaction {
  .notification-icon {
    background-color: $red;
  }
}

.notifications-item-addtocollection {
  .notification-avatars .image {
    background-color: $white;
  }
}

.notification-content {
  margin-top: 1rem;
  a {
    color: $grey-dark;
    font-weight: bold;
  }
}

.notification-time {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.notification-comment-wrapper{
  overflow: auto;
}

.notification-teasercard {
  .teasercard {
    .author {
      margin-bottom: 1rem;
      small {
        margin-left: 0;
        display: block;
      }
    }
    .card-content {
      position: relative;
      padding: 1rem;
      .card-content-link {
        color: $grey-dark;
      }
      .content {
        .title {
          margin-bottom: 0;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
