.post-comments {
  max-width: 100%;
  margin-bottom: 3rem;
}

.comment {
  clear: both;
  float: left;
  max-width: 100%;
  //overflow: hidden;
  padding-bottom: 1rem;
  position: relative;

  .comment-avatar {
    a {
      display: block;

      &:after {
        content: ' ';
        position: absolute;
        left: 100%;
        top: 50%;
        width: 0;
        height: 0;
        pointer-events: none;
        border: 4px solid transparent;
        border-top: 4px solid $grey-dark;
        border-right: 4px solid $grey-dark;
        transform: rotate(45deg);
        margin-top: -4px;
      }
    }
  }

  @include tablet {
    max-width: 80%;
    padding-bottom: 1rem;
    .comment-avatar.is-32x32 {
      width: 48px;
      height: 48px;
    }
  }

  .comment-author {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;

    @include tablet {
      flex-direction: row;
      align-items: center;
    }

    .comment-author-user {
      display: inline-flex;
      align-items: center;
    }
  }

  &.comment-sent .comment-author {
    align-items: flex-end;

    @include tablet {
      justify-content: flex-end;
    }
  }

  .comment-author .author-mini-bio {
    font-size: 0.9rem;
    color: $grey;
    @include tablet {
      line-height: 1.2em;
      margin-top: 0.25rem;
      margin-left: 0.25rem;
    }
  }

  .comment-author button.button {
    height: auto;
    padding: 0;
  }

  .comment-author-username {
    color: $dark;
    // margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    float: left;
    @include tablet {
      margin-bottom: 0.5rem;
      margin-top: 0.75rem;
    }
    small {
      margin-left: 0.5rem;
    }
  }
  .comment-meta {
    display: flex;
    clear: left;
    align-items: center;
    a {
      color: $grey;
    }
  }
  .comment-moderate {
    display: flex;
    align-items: center;
    clear: both;
    a {
      display: inline-block;
      color: $dark;
    }
  }
  .comment-date,
  .comment-moderate-meta {
    font-size: 0.9em;
    clear: left;
    margin-right: 0.5rem;
  }
  .comment-moderate-meta {
    padding: 0;
    color: $danger;
  }
  .comment-content {
    position: relative;
    clear: left;
    background: $grey-dark;
    border-radius: 4px;
    padding: 0.5em 0.75em;
    float: left;
    margin-bottom: 0 !important;
    font-family: $font-brand;
    color: $white;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-wrap: break-word;
    a {
      text-decoration: underline;
      color: $white;
      font-family: $font-brand-bold;
      line-break: anywhere;
    }
    @include tablet {
      padding: 0.75rem 1rem;
    }

    &.comment-author-blocked {
      background: $grey-light;
      font-style: italic;
    }
    &.comment-author-blocked:after {
      border-right-color: $grey-light;
    }
  }
  &.comment-sent {
    float: right;
    .media-left {
      -webkit-order: 3;
      -ms-flex-order: 3;
      order: 3;
      margin-right: 0;
      margin-left: 1rem;
    }
    .comment-avatar a:after {
      left: -8px;
      transform: rotate(-135deg);
      border-top-color: $grey-dark;
      border-right-color: $grey-dark;
    }
    .comment-author-username {
      float: right;
    }
    .comment-meta {
      float: right;
      clear: right;
    }
    .comment-moderate {
      justify-content: flex-end;
    }
    .comment-date {
      text-align: right;
      clear: right;
    }
    .comment-content {
      background: $grey-dark;
      float: right;
      color: $white;
      clear: right;
      &:after {
        right: 0;
        left: auto;
        border: 0.8rem solid transparent;
        border-left-color: $grey-dark;
        border-right: 0;
        border-top: 0;
        margin-left: 0;
        margin-right: -0.75rem;
      }
    }
  }
  &.comment-deleted {
    .comment-content {
      color: $grey;
      background: $light;
      &:after {
        display: none;
      }
    }
  }
  &.comment-unpublished {
    .comment-content {
      background: $grey-light;
    }
  }

  .comment-reactions {
    position: absolute;
    top: calc(32px + 1rem);
    left: -0.25rem;
    @include tablet {
      top: calc(48px + 0.25rem);
      left: 0.25rem;
    }
    .control {
      display: flex;
      flex-direction: column;
    }
  }
  &.comment-sent .comment-reactions {
    left: auto;
    right: -0.25rem;
    @include tablet {
      right: 0.25rem;
    }
  }
}

.comment-create-form {
  position: sticky;
  bottom: $navbar-height-mobile;
  left: 0;
  right: 0;
  background-color: lighten($yellow, 10%);
  z-index: 20;
  padding: 0.75rem 1rem;
  border-radius: $radius $radius 0 0;
  z-index: 41;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 0 !important;

  .resizeabletextarea {
    resize: vertical !important;
  }

  .mention-control {
    position: unset !important;
    > div {
      position: unset !important;
    }
  }

  .mention-media {
    position: relative;
  }

  .media {
    flex-direction: row-reverse;
    align-items: flex-end;
    .media-left {
      margin-right: 0;
      margin-left: 0.5rem;
      .button {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        padding-top: calc(0.75em - 1px);
        padding-bottom: calc(0.75em - 1px);
        height: 2.95rem;
      }
    }
  }
  .media-left .image {
    width: 40px;
    height: 40px;
  }
}

@include tablet {
  .comment-create-form {
    bottom: calc(#{$navbar-height-mobile} + 1rem);
    padding: 1rem;
    margin-left: 0;
    margin-right: 0;
    border-radius: $radius;
  }
}

@include widescreen {
  .comment-create-form {
    bottom: 1rem;
  }
}

.new-comments-button {
  position: sticky;
  bottom: calc(#{$navbar-height-mobile} + 5rem);
  left: 0;
  right: 0;
  z-index: +1;

  @include tablet {
    bottom: calc(#{$navbar-height-mobile} + 7rem);
  }

  @include widescreen {
    bottom: calc(#{$navbar-height-mobile} + 3rem);
  }
}

.reply-comment-button {
  background-color: $yellow;
  border: none;
}

.post-comments.not-authenticated {
  padding-bottom: 10rem;
  .comment:nth-child(2) {
    opacity: 0.25;
  }
}
