.find-msers-body {
  @include widescreen {
    .site-nav {
      left: 1rem;
      transform: translateX(0);
    }
  }
}

#find-msers {
  @include widescreen {
    background-color: $cyan;
  }
  .find-msers-search-tabs {
    padding: 1rem 1rem 0.5rem 1rem;
    .tabs {
      margin-bottom: 0;
    }
    @include widescreen {
      padding: 2rem 0;
      position: fixed;
      top: 105px;
      left: 50%;
      z-index: 10;
      transform: translateX(-50%);
    }
  }
  .find-msers-buttons {
    position: sticky;
    top: 56px;
    z-index: 10;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    background-color: $white-bis;
    .button {
      margin-bottom: 0;
    }
    @include desktop {
      top: 105px;
    }
    @include widescreen {
      display: none;
    }
  }
}

#find-msers.map-view {
  .find-msers-buttons {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.findmsers-results {
  padding: 0;
  @include widescreen {
    padding: 0;
    padding-right: 1rem;
    padding-top: 2rem;
    width: calc(360px + 1rem);
    position: fixed;
    right: 0;
    top: 105px;
    overflow: scroll;
    bottom: 0;
    z-index: 10;
  }
  @include fullhd {
    width: calc(400px + 1rem);
  }
}

.findmsers-results .findmsers-results-empty {
  margin-bottom: 1rem;
  .card {
    background-color: $danger;
    color: $white;
    border-radius: $radius;
    font-family: $font-brand-bold;
    text-align: center;
    font-size: 1.2rem;
  }
}

.findmsers-results .profile-card {
  .media {
    margin-bottom: 0.5rem;
    align-items: flex-start;
  }
  .tags {
    margin-bottom: 0;
  }

  .tag {
    background-color: $green-alt;
  }
}

.findmsers-pagination {
  background-color: $light;
  padding: 1rem;
  border-radius: $radius;
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
  box-shadow: $box-shadow;
}

.find-msers-map {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: $navbar-height-mobile;
  z-index: 9;
  display: none;

  &.is-open {
    display: block;
  }

  @include desktop {
    top: 105px;
  }

  @include widescreen {
    display: block;
    bottom: 0;
  }

  .findmsers-map-fun-marker {
    .findmsers-map-fun-marker-icon img {
      display: inline-block;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  #geo-search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $cyan;
  }
}

.leaflet-popup .profile-card {
  margin-bottom: 0;
}

.find-msers-logged-out-hero {
  background-color: #3f4659;
}
