.findmsers-filters {
  padding: 1rem;
  padding-bottom: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  transform: translateY(100%);
  z-index: 11;
  background-color: $white;
  transition: ease-in-out all 0.25s;

  &.is-open {
    transform: translateY(0);
  }

  .buttons {
    margin-bottom: 0.5rem;
  }

  .findmsers-filters-close {
    margin-left: 0.5rem;
  }

  @include desktop {
    top: 105px;
  }

  @include widescreen {
    transform: translateY(0);
    border-radius: $radius;
    top: calc(105px + 2rem);
    left: calc(80px + 2rem);
    right: auto;
    max-height: calc(100vh - 1.5px - 2rem - 2rem);
    bottom: auto;
    width: 300px;
    box-shadow: $box-shadow;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .findmsers-filters-close {
      display: none;
    }
  }

  h3.title {
    margin-top: 1rem;
    border-top: 1px solid $grey-lighter;
    padding-top: 0.5rem;
    margin-bottom: 0;
    a {
      color: $text;
      display: block;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      span.icon {
        position: absolute;
        right: 0;
        top: 0.25rem;
        color: $grey-light;
      }
    }
  }

  ul li {
    margin-bottom: 0.25rem;
  }

  ul li .is-checkradio[type='checkbox'] + label {
    font-size: 0.9rem;
  }
}

.buttons.close-filters-buttons {
  position: fixed;
  background-color: $white;
  bottom: $navbar-height-mobile;
  left: 0;
  right: 0;
  padding: 0.5rem 0;
  margin-bottom: 0;
  z-index: 12;
  border-top: 1px solid $light;
  display: none;
  .button {
    margin-bottom: 0;
  }
  &.is-open {
    display: flex;
  }
  @include widescreen {
    display: none;
    &.is-open {
      display: none;
    }
  }
}

.findmsers-results-count {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
