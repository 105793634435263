.inbox,
.inbox-chatroom {
  padding-bottom: 8rem;
  .chatroom-header .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .chatroom-header-name {
      font-family: $font-brand-bold;
      flex: 1;
      line-height: 1.2;
      font-size: 1.5rem;
      padding-right: 1rem;
    }
    .chatroom-header-end {
      margin-left: auto;
    }
  }
}

.inbox-chatroom-messages {
  padding-top: 1rem;
}

.inbox-chatroom-message-form {
  position: fixed;
  bottom: $navbar-height-mobile;
  left: 0;
  right: 0;
  z-index: $chatroom-form-z;
  padding: 0.75rem 1rem;
  background-color: $grey-dark;
  border-radius: $radius $radius 0 0;
  @include tablet {
    bottom: 60px;
  }
  @include desktop {
    left: 50%;
    right: auto;
    width: $container-narrow-width;
    margin-left: -($container-narrow-width / 2);
  }
  @include widescreen {
    bottom: 1rem;
    border-radius: $radius;
  }

  .textarea {
    background-color: lighten($grey-dark, 25%);
    border-color: lighten($grey-dark, 30%);
    color: $white;
    &::placeholder {
      color: $light;
    }
  }

  .media {
    flex-direction: row-reverse;
    align-items: center;
    .media-left {
      margin-right: 0;
      margin-left: 1rem;
    }
  }
  .media-left .image {
    width: 40px;
    height: 40px;
  }
  button.button.is-rounded {
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: darken($grey-dark, 5%);
    color: $white;
  }
}

.chatroom-participants-grid {
  .column {
    position: relative;
    .delete {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(28px);
    }
  }
}
