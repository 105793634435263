.site-nav {
  background-color: $white-ter;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $navbar-height-mobile;
  border-top: 1px solid $grey-lighter;
  z-index: 2000;
  display: flex;
  align-items: stretch;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @include widescreen {
    right: auto;
    top: calc(105px + 2rem);
    bottom: auto;
    height: auto;
    width: calc(
      #{$widescreen - $container-offset - $container-narrow-width} - 21rem
    );
    left: 50%;
    transform: translateX(
      -(min($widescreen, $container-max-width) - $container-offset) / 2
    );
    border: 2px solid $grey;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    align-items: flex-start;
    border-radius: $radius;
  }

  @include fullhd {
    transform: translateX(
      -(min($fullhd, $container-max-width) - $container-offset) / 2
    );
  }
}

.site-nav .site-nav-buttons {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

  @include widescreen {
    flex-direction: column;
  }

  a,
  .button,
  .nav-user {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    margin: 0 0.25rem;
    height: 50px;
    border-radius: $radius;
    color: $dark;
    border-color: transparent;
    background-color: transparent;

    span.icon {
      width: 1.75rem;
      height: 1.75rem;
      margin-left: 0;
      margin-right: 0;
      svg {
        width: 1.75rem !important;
        height: 1.75rem !important;
      }
    }

    .nav-label {
      display: none;
    }

    &:hover {
      background-color: darken($light, 5%);
    }
    &.is-active {
      background-color: $white;
    }

    &.has-notification {
      position: relative;
    }

    .nav-count {
      position: absolute;
      background-color: $grey-dark;
      color: $white;
      top: 0rem;
      left: 50%;
      margin-left: 0.2rem;
      font-weight: bold;
      @include widescreen {
        left: auto;
        top: 0.25rem;
        right: 0rem;
        margin-left: 0;
      }
    }

    &.nav-notifications .nav-count {
      background-color: $red;
    }

    @include widescreen {
      width: 100%;
      height: calc(80px - 1rem);
      margin-bottom: 0.5rem;
      span.icon {
        width: 2rem;
        height: 2rem;
        svg {
          width: 2rem !important;
          height: 2rem !important;
        }
      }
    }
  }

  @include until-widescreen {
    .nav-signout {
      display: none;
    }
    .nav-user {
      .image {
        height: 32px;
        width: 32px;
      }
    }
  }

  .nav-post {
    background-color: $yellow;
    &:hover {
      background-color: darken($yellow, 5%);
    }
  }
}

@include fullhd {
  body:not(.explore-body) .site-nav {
    width: calc(
      #{$fullhd - $container-offset - $container-narrow-width} - 21.5rem
    );

    a,
    .button,
    .nav-user {
      padding: 0.25rem 0.5rem;
      justify-content: flex-start;
      span.icon {
        width: 3rem !important;
      }
      .nav-label {
        display: block;
        margin-left: 0.5rem;
        margin-top: 0.5rem;
        font-size: 1.4rem;
        font-family: $font-brand-bold;
      }
    }
  }
}

.site-nav-logged-out {
  padding: 0;
  border: none;
}

// TOPNAV

html.has-navbar-fixed-top {
  padding-top: 56px;
  @include desktop {
    padding-top: 105px;
  }
}

.topnav.is-fixed-top {
  box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
    0 0px 0 1px rgba($scheme-invert, 0.02);
  min-height: 56px;
  display: block;

  @include desktop {
    min-height: 105px;
  }

  .navbar-motion {
    width: 100%;
    min-height: 56px;
  }

  .container {
    display: flex;
  }
  .navbar-right {
    margin-left: auto;
    display: flex;
    .navbar-item:last-child {
      padding-right: 0;
    }
  }

  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    svg {
      margin-top: 0.25rem;
    }
    span {
      font-size: 1.4rem;
      @include mobile {
        display: none;
      }
    }
  }

  .navbar-item.navbar-item-donate {
    display: flex;
    align-items: center;
  }

  @include mobile {
    .navbar-item:not(.navbar-item-donate) {
      padding-left: 0;
      padding-right: 0;
      .button {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
    .navbar-item.navbar-item-donate {
      span.icon {
        margin-right: calc(-0.5rem + 1px);
      }
    }
  }

  @media screen and (max-width: 370px) {
    .navbar-item.navbar-item-donate {
      display: none;
    }
  }
}

.shiftms-logo {
  font-family: $font-brand-bold;
  color: $black;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: $black !important;
  }
  svg {
    margin-right: 0.5rem;
  }
}

.learn-nav {
  display: none;
  font-family: $font-brand-bold;
  border-top: 1px solid $light;
  @include desktop {
    display: flex;
    width: 100%;

    .container {
      max-width: 880px !important;
    }

    .learn-nav-item {
      width: 20%;
      align-items: center;
      display: flex;
    }
  }

  @include widescreen {
    .container {
      max-width: 940px !important;
    }
  }

  .learn-nav-heading {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }

  .navbar-dropdown {
    background-color: #ddd;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    .navbar-item {
      padding: 1rem 1rem;
      font-size: 1rem;
    }
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: $grey-dark;
  }

  .navbar-link {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    padding-left: 1rem;

    &:hover {
      color: $grey-dark;
    }
  }

  .learn-nav-symptoms {
    .navbar-link:hover {
      border-bottom: 4px solid $cyan;
    }
    .navbar-dropdown .navbar-item:hover {
      background-color: $cyan;
    }
  }

  .learn-nav-diagnosis {
    .navbar-link:hover {
      border-bottom: 4px solid $yellow;
    }
    .navbar-dropdown .navbar-item:hover {
      background-color: $yellow;
    }
  }

  .learn-nav-treatment {
    .navbar-link:hover {
      border-bottom: 4px solid $green-alt;
    }
    .navbar-dropdown .navbar-item:hover {
      background-color: $green-alt;
    }
  }

  .learn-nav-living {
    .navbar-link:hover {
      border-bottom: 4px solid $orange;
    }
    .navbar-dropdown .navbar-item:hover {
      background-color: $orange;
    }
  }
}
