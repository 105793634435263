.nps-modal {
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  @include desktop {
    height: auto;

    .modal-header h2.is-size-6 {
      font-size: 1.25rem !important;
    }
  }

  .card-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
  }

  .nps-modal-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  .nps-modal-content-center {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    h2 {
      margin-bottom: 3rem;
    }

    .nps-modal-image {
      display: block;
      box-shadow: $shadow;
      border-radius: $radius;
    }
  }

  .nps-modal-content-stretch {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;

    .textarea {
      flex-grow: 1;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nps-modal-background-grey-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../public/assets/images/nps/grey_circle.png);
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: -100% center;

    @include desktop {
      background-size: 50%;
      background-position: -50% center;
    }
  }

  .nps-modal-background-blue-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../public/assets/images/nps/circle.png);
    background-repeat: no-repeat;
    background-size: 105px;
    background-position: 60px -10px;

    @include desktop {
      background-position: center 75%;
    }

    &.nps-modal-background-blue-circle-lower {
      background-position: -10px 70%;

      @include desktop {
        background-position: center;
      }
    }
  }

  .nps-modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../public/assets/images/nps/squiggle-mobile.png);
    background-repeat: no-repeat;
    background-position: center 10%;
    background-size: 200%;

    &.nps-modal-background-more-feedback-left,
    &.nps-modal-background-more-feedback-right {
      display: none;
    }

    @include desktop {
      display: none;
    }

    &.nps-modal-background-left,
    &.nps-modal-background-more-feedback-left {
      background-position: -30% 20%;

      @include desktop {
        background-size: 100%;
        left: 66.666666%;
        top: 0;
        display: block;
      }
    }

    &.nps-modal-background-right,
    &.nps-modal-background-more-feedback-right {
      background-position: 130% 20%;

      @include desktop {
        background-size: 100%;
        left: -66.666666%;
        top: -10%;
        display: block;
      }
    }
  }
}

.nps-score {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  @include desktop {
    height: auto;
    display: block;
  }
}

.nps-score-labels-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nps-score-buttons {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include desktop {
    flex-direction: row-reverse;
  }
}

.nps-score-button.is-primary {
  color: $dark;
  &:hover,
  &:active {
    color: $white;
    background-color: $dark !important;
  }
}

@media screen and (max-height: 750px) {
  .nps-score-button {
    font-size: 0.75rem;
  }
}

@include desktop {
  .nps-score-buttons .nps-score-button {
    border-radius: $radius;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.nps-radios {
  .radio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    input {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.nps-modal-img {
  display: block;
}
