.follow-buttons {
  .inbox-button {
    @include mobile {
      .icon {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}

.profile-follow-list {
  margin-top: 2rem;
  li {
    border-bottom: 1px solid $light;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    .profile-follow-list-profile-image {
      width: 72px;
      height: 72px;
    }
    .profile-follow-list-profile-name {
      font-family: $font-brand-bold;
      display: block;
      font-size: 1.2rem;
    }
    .profile-follow-list-profile-bio {
      font-family: $font-brand;
      color: $text;
      display: block;
    }
  }
}
