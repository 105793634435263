.collections-dialog {
  .collections-list-new {
    border-top: 1px solid $light;
    padding-top: 1rem;
  }
}

.collections-cards {
  li {
    margin-bottom: 1rem;
  }
}

.collection-card {
  border-radius: $radius;
  .collection-card-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: $radius;
    padding: 1rem 1rem 0 1rem;
    .collection-card-header-image {
      display: flex;
      align-items: center;
      margin-right: 1rem;
    }
    .image {
      background-color: rgba($dark, 0.1);
      border-radius: $radius;
    }
  }
  .collection-card-content {
    padding: 1rem;
  }
}

.collection-space {
  height: 100%;
  .collection-space-header {
    border-radius: $radius;
    .image {
      background-color: rgba($dark, 0.1);
      border-radius: $radius;
      img {
        border-radius: $radius $radius 0 0;
      }
    }
  }
  .collection-space-content {
    padding: 1rem;
    flex: 1 1 auto;
  }
}

.collection {
  padding-bottom: 1rem;
  @include tablet {
    padding-bottom: 2rem;
  }
  .collection-image {
    margin-bottom: 1rem;
  }
  .collection-header {
    margin-bottom: 1rem;
    font-family: $font-brand;
    @include mobile {
      .hero-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 2rem;
        padding-bottom: 1rem;
      }
    }
    @include tablet {
      margin-bottom: 2rem;
    }

    h1 {
      margin-bottom: 1rem;
    }
  }
}

.collections-list {
  li {
    margin-bottom: 0.25rem;
  }
}

.collection-toggle-button {
  justify-content: flex-start;

  &.is-primary.is-light,
  &.is-primary.is-light:hover {
    color: #000;
  }
}
