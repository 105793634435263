.post-bottom {
  height: 4rem;
  @include tablet {
    height: 6rem;
  }
}
.poll-vote-count-wrapper {
  position: relative;
  background-color: $white;
}

.poll-vote-count-bar {
  border-radius: $radius !important;
  text-align: center;
}

.poll-vote-count {
  position: absolute;
  color: $white;
  top: 11%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  padding: 0px 20px;
}

.is-grey-dark {
  color: $grey-dark !important;
}

.create-poll-options {
  .icon {
    color: $grey-mid;
  }
} 
