#stream {
  overflow-anchor: none;
  position: relative;
}

.side-card {
  .card {
    border-radius: $radius;
  }

  @include widescreen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: calc(105px + 2rem);
    right: 2rem;
    bottom: 60px;
    margin-left: 2rem;
    margin-bottom: 2rem;
    flex-shrink: 0;
    width: calc(100vw - #{$container-narrow-width} - 2rem);
    max-width: 17rem;
  }

  @include widescreen {
    bottom: 0;
  }

  @include fullhd {
    right: 50%;
    transform: translateX(
      (min($fullhd, $container-max-width) - $container-offset) / 2
    );
  }

  @include massive {
    width: 21rem;
    max-width: 21rem;
    transform: translateX(min($fullhd, $container-max-width/2));
  }

  /* .card .card-content {
    @include desktop-only {
      padding: 1rem;
    }
  } */
}

@media screen and (min-width: $widescreen) and (min-height: $shortscreen) {
  .side-card {
    height: calc(100vh - 4rem - 105px - #{$navbar-height-mobile});
    margin-bottom: 0;
  }
  .not-authenticated .side-card {
    height: calc(100vh - 4rem - 105px - #{$navbar-height-mobile} - 5rem);
  }
}

@media screen and (min-width: $widescreen) and (min-height: $shortscreen) {
  .side-card {
    height: calc(100vh - 4rem - 105px);
  }
  .not-authenticated .side-card {
    height: calc(100vh - 4rem - 105px - 5rem);
  }
}

@media screen and (max-width: ($widescreen - 1px)) {
  .layout #stream {
    .stream.has-sidebar {
      padding-top: 200px;
    }
    .stream-sidebar {
      position: absolute;
      top: 0;
      left: 0;
      height: 200px;
      width: 100%;
      overflow: hidden;
      .widget {
        display: none;
      }
    }
  }
}

@include mobile {
  .layout #stream {
    .stream.has-sidebar {
      padding-top: 130px;
    }
    .stream-sidebar {
      height: 120px;
      .card-content {
        padding: 1rem;
        .content {
          margin-bottom: 0.5rem;
          .title:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.join-card .card {
  background-color: $green;
  .card-image {
    text-align: center;
    img {
      margin-top: 1rem;
      max-height: 200px;
    }
  }
}

.widget {
  .widget-link {
    display: block;
    .card {
      border-radius: $radius;
      background-color: $green-alt;
      color: $dark;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .card-description {
        display: none;
      }
      .card-image {
        img {
          border-radius: $radius $radius 0 0;
        }
        .widget-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: $radius $radius 0 0;
        }
        .card-image-play {
          position: absolute;
          bottom: 0.5rem;
          left: 0.5rem;
          color: $dark;
          background-color: #fff;
          border-radius: $radius;
          height: 3rem;
          width: 3rem;

          @include tablet {
            height: 5rem;
            width: 5rem;
          }
        }
      }
      .card-type {
        margin-right: 0.5rem;
      }
      .card-content {
        padding: 0.5rem 0.5rem 0 0.5rem;
        margin-top: auto;
        .media-left {
          margin-right: 0.5rem;
        }
        .media-content {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          .card-title h3 {
            display: inline;
          }
        }
      }
    }
  }
  .widget-link:hover {
    .card {
      box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.2),
        0 0px 0 1px rgba($scheme-invert, 0.02);
    }
    .card .card-image .card-image-play {
      background-color: $dark;
      color: #fff;
    }
  }
}

.stream-sidebar .widget .widget-link .card .card-image .card-image-play {
  height: 3rem;
  width: 3rem;
}

@include widescreen {
  .explore-items-container {
    .widget,
    .widget .widget-link,
    .widget .widget-link .card {
      height: 100%;
    }
    .widget-link .card .card-image .card-image-play {
      height: 3rem;
      width: 3rem;
    }
  }
}

#stream .posts .widget {
  margin-bottom: 1rem;
}

.latest-profiles-card {
  background-color: transparent;
  .profile-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .card-content .profile-grid li.column.is-one-third {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  @include until-widescreen {
    .buttons {
      display: none;
    }
    h2.title {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      max-width: $container-narrow-width;
      margin-left: auto;
      margin-right: auto;
    }
    .card-content {
      padding: 2rem;
      .profile-grid {
        @include touch {
          margin-left: -2rem;
          margin-right: -2rem;
        }
        flex-wrap: nowrap;
        padding-left: 0.5rem;
        /* Make this scrollable when needed */
        overflow-x: auto;
        /* We don't want vertical scrolling */
        overflow-y: hidden;
        /* Make an auto-hiding scroller for the 3 people using a IE */
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /* For WebKit implementations, provide inertia scrolling */
        -webkit-overflow-scrolling: touch;
        /* We don't want internal inline elements to wrap */
        white-space: nowrap;
        /* Remove the default scrollbar for WebKit implementations */
        &::-webkit-scrollbar {
          display: none;
        }

        li.column.is-one-third {
          width: auto;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          .avatar-link {
            display: block;
            width: 64px;
          }
        }
      }
    }
    .container.is-narrow {
      max-width: none;
    }
  }
  @include mobile {
    h2.title {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    .card-content {
      padding: 1rem;
      .profile-grid {
        margin-left: -1rem;
        margin-right: -1rem;
        li.column.is-one-third {
          .avatar-link {
            width: 48px;
            span.tag {
              padding-left: 0.1em;
              padding-right: 0.1em;
            }
          }
          .image.is-64x64 {
            height: 48px;
            width: 48px;
          }
        }
      }
    }
  }
  @include widescreen {
    background-color: $info;
    margin-bottom: 1rem;
    .card-content {
      padding-bottom: 1rem;
    }
  }
  @include massive {
    .card-content {
      .profile-grid {
        li.column.is-one-third {
          width: 25%;
        }
      }
    }
  }
  @media screen and (min-width: $desktop) and (max-height: $shortscreen) {
    h2.title {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }
  @include widescreen-only {
    h2.title {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }
}

.not-authenticated .stream-header-not-authenticated {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include tablet {
    height: 200px;
  }

  @include widescreen {
    height: auto;
  }
  .content {
    h1 {
      margin-bottom: 0;
    }
  }
}

@include touch {
  .post-profile-card {
    margin-top: 1rem;
    margin-bottom: 6rem;
  }
}

.obs {
  height: 100px;
}
