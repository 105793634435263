.profile-card {
  margin-bottom: 1rem;
  display: block;
  position: relative;

  .profile-card-link {
    position: static;
    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .card {
    border-radius: $radius;
  }
  h2 {
    margin-bottom: 0;
    a {
      color: $dark;
    }
  }
  .media {
    align-items: center;
  }
  .tags {
    margin-bottom: 0;

    .tag {
      background-color: $green-alt;
    }
  }
  .profile-card-intro {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    .profile-card-flag {
      font-size: 2rem;
      line-height: 2rem;
      margin-right: 1rem;
    }
    .profile-card-age {
      font-family: $font-brand;
      margin-top: 0.5rem;
    }
  }

  .profile-card-list {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .bio {
    margin-bottom: 0.5rem;
  }

  .profile-card-buttons {
    overflow: auto;
    display: flex;
    flex-basis: 50%;
    flex-wrap: nowrap;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    .button {
      margin: 0 0.25rem;
    }
    .follow-button,
    .inbox-button {
      position: relative;
      z-index: +1;
    }
  }
}
