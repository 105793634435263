#search {
  padding-bottom: 1rem;
  @include tablet {
    padding-bottom: 2rem;
  }

  @include desktop {
    padding-top: 2rem;
  }

  @include widescreen {
    padding-left: 110px;
    padding-right: 110px;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;

    @include widescreen {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.search-form {
  margin-bottom: 1.5rem;
  background-image: url(../../public/assets/images/search-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: ease-in-out all 0.25s;
  h1 {
    line-height: 1.2;
    margin-bottom: 0.25rem;
  }
  &.is-small {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @include mobile {
    .control.has-icons-left .input.is-medium ~ .icon {
      font-size: 1rem;
    }
  }
}

.search-tabs ul {
  justify-content: center;
  flex-shrink: 1;
  li {
    white-space: normal;
  }
  li a {
    background-color: $white-bis;
    text-align: center;
  }
}

.explore-items-container {
  padding-top: 1rem;
  @include tablet {
    padding-top: 0.5rem;
  }
  .explore-item {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 0;
    }
  }
}

@include tablet {
  .explore-items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
    margin: 0 -0.5rem;

    .explore-item {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 50%;
      align-self: auto;
      order: 0;
      padding: 1rem 0.5rem;
      width: 50%;
    }
  }
}

@include widescreen {
  .explore-items-container {
    .explore-item {
      flex-shrink: 1;
      flex-basis: 25%;
      width: 25%;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      padding-left: 0;
      padding-right: 0;
      .card .card-description {
        display: block;
        padding: 0.5rem;
        font-family: $font-brand;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      .card .card-title {
        display: none;
      }
    }

    .explore-item:nth-child(6n),
    .explore-item:nth-child(6n + 1) {
      flex-basis: 40%;
      flex-grow: 1;
      max-width: 45%;
      width: auto;
      .card .card-description {
        display: none;
      }
      .card .card-title {
        display: inline;
      }
    }
  }
}

.horizontal-scroll {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin: 0 calc(-1 * 0.5rem);
  margin-bottom: calc(0.5rem * 0.5 - 0.5rem * 0.5);
  padding-bottom: calc(0.5rem * 0.5 - 0.5rem * 0.5);
  overflow-x: auto;
  scroll-snap-type: x proximity;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.horizontal-scroll:hover {
  scrollbar-color: #ccc #eee;
}

.horizontal-scroll::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.horizontal-scroll::-webkit-scrollbar-track {
  margin: 1rem;
  background: center / 100% 25% no-repeat
    linear-gradient(0deg, transparent, transparent);
  border-radius: 1rem;
}
.horizontal-scroll:hover::-webkit-scrollbar-track {
  background-image: linear-gradient(0deg, lightgray, lightgray);
}
.horizontal-scroll::-webkit-scrollbar-track:hover {
  background-image: linear-gradient(0deg, silver, silver);
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 1rem;
}
.horizontal-scroll:hover::-webkit-scrollbar-thumb {
  background: lightgrey;
}
.horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: silver;
}

.horizontal-scroll:before,
.horizontal-scroll:after {
  content: '';
  min-width: 2rem;
}

.horizontal-scroll-item {
  margin: 0 calc(0.5rem * 0.5);
  scroll-snap-align: center;
}
