html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: $white-bis;
  min-height: 100%;
}

@include until-widescreen {
  .layout {
    padding-bottom: $navbar-height-mobile;
  }
}

@include desktop {
  .is-hidden-shortscreen {
    @media (max-height: $shortscreen) {
      display: none !important;
    }
  }
}

@include desktop {
  .is-hidden-desktop-until-fullhd {
    display: none !important;
    @include massive {
      display: block !important;
    }
  }
}

.is-narrow,
.container.is-narrow {
  max-width: $container-narrow-width !important;
  @include mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.container.is-flush {
  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

.page-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include widescreen {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2rem;
    padding-bottom: 2rem;
    &.has-sidebar {
      justify-content: flex-start;
      .container {
        margin: 0;
      }
    }
  }

  /* @include desktop-only {
    max-width: none;
    .container {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  } */

  @include widescreen {
    &.has-sidebar {
      justify-content: center;
    }
  }
}

@include widescreen {
  .not-authenticated .page-container.has-sidebar {
    justify-content: center;
  }
}

// LOADING
.loading-block {
  position: relative;
  padding: 5em;
  &:after {
    @include loader;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 3rem;
    width: 3rem;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
  }
}

// LOADER AVATAR
.loading-avatar {
  &:after {
    @include loader;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5rem;
    width: 1.5rem;
    margin-top: -0.8rem;
    margin-left: -0.8rem;
  }

  &.is-rounded {
    border-radius: 50%;
  }
}
// FONT
.is-font-brand {
  font-family: $font-brand;
}

.is-font-brand-bold {
  font-family: $font-brand-bold;
}

// COLOURS
.button.is-pink {
  background-color: $pink;
  border-color: transparent;
  color: findColorInvert($pink);
  &:hover,
  &.is-hovered {
    background-color: darken($pink, 2.5%);
    border-color: transparent;
    color: findColorInvert($pink);
  }
}

// MODALS
.MuiPaper-root,
.modal {
  &.small-modal .modal-content {
    max-width: 340px;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: relative;
    z-index: +1;
    .title {
      margin-bottom: 0;
    }
  }
  .modal-close-button {
    justify-content: flex-end;
    margin-left: 0.25rem;
    .button {
      border-radius: $radius-rounded;
      margin-bottom: 0;
    }
  }
  .modal-footer-buttons {
    .button {
      flex: 1;
    }
  }
}

.modal {
  .card {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@include tablet {
  .large-dialog {
    min-width: 600px;
  }
}

// MATERIAL UI MODAL
// USED FOR DIALOGS
.MuiDialog-root {
  z-index: $modal-z !important;
  .MuiDialog-paper {
    margin: 1rem;
    min-width: calc(100% - 2rem);
    @include tablet {
      min-width: 400px;
    }
    &.MuiDialog-paperFullScreen {
      margin: 0;
      min-width: 100%;
      & > .card {
        border-radius: 0;
      }
    }
  }
}

// REACT MODAL
// USED FOR FULL SCREEN
.ReactModal__Overlay {
  z-index: $react-modal-z;
  background-color: #fff;
}

.ReactModal__Content {
  top: 56px !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;

  @include desktop {
    top: 105px !important;
  }
}

// BUTTONS

.button.is-google {
  background-color: #e74f43;
  color: #fff;
  &:hover {
    background-color: darken(#e74f43, 5%);
    color: #fff;
  }
}

.button.is-facebook {
  background-color: #4172b8;
  color: #fff;
  &:hover {
    background-color: darken(#4172b8, 5%);
    color: #fff;
  }
}

.button.is-signin {
  font-weight: bold;
}

.button {
  .button-labels {
    .button-label-hover {
      display: none;
    }
  }
  &:hover {
    .button-labels {
      .button-label-default {
        display: none;
      }
      .button-label-hover {
        display: inline;
      }
    }
  }
}

.button-has-count {
  .icon {
    margin-left: calc(-0.5em - 1px) !important;
    margin-right: calc(-0.5em - 1px) !important;
  }
}

.buttons .buttons-text {
  margin-bottom: 0.5rem;
}

// STICKY HEADER
.sticky-header {
  position: sticky;
  top: 56px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: $white;
  border-bottom: 1px solid $light;
  z-index: +1;

  @include tablet {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @include desktop {
    top: 105px;
  }
}

// FORMS
.settings-switch {
  .switch[type='checkbox'] + label {
    display: block;
    padding-right: 3.5rem;
    padding-left: 0;
    &::before,
    &:before {
      right: 0;
      left: auto;
    }
    &::after,
    &:after {
      right: 1.625rem;
      left: auto;
    }
  }
  .switch[type='checkbox']:checked + label::after {
    right: 0.25rem;
  }
  .loader {
    position: absolute;
    right: 3.5rem;
    top: 0.75rem;
  }
}

// PRIVACY LOCK
.privacy-lock {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-family: $font-brand-bold;
  background-color: $white;
  border-radius: $radius-rounded;
  padding: 0.25rem 0.5rem;
  color: $grey-dark;
  line-height: 1;
  span {
    display: block;
    padding-top: 0.2rem;
    padding-left: 0.25rem;
  }

  &.admin-locked {
    background-color: $danger;
    color: $white;
  }
}

// CONTRAST ISSUES
.has-background-dark .has-text-white a:not(.button) {
  color: $white;
  text-decoration: underline;
  font-family: $font-brand-bold;
}

// HERO IMAGES
.hero-decoration {
  max-height: 200px;
  float: right;

  @include tablet {
    max-height: none;
  }

  @include widescreen {
    float: none;
    margin-left: calc(100% + 2rem);
    margin-top: -60px;
  }
}

.has-background-info {
  a:not(.button) {
    color: $text;
    text-decoration: underline;
  }
}

.row-gap {
  row-gap: 0.75rem;
}

// Snackbar
.snackbar {
  bottom: 60px !important;
  z-index: 2002 !important;
}

.error-next > div {
  background-color: rgb(250 250 250) !important;
  height: unset !important;
  margin: 20px !important;
}

.is-alt-green {
  color: $green-alt;
}
