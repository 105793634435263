.inbox-user-list {
  li {
    margin: 0.5rem 0;
    .button {
      justify-content: flex-start;
      font-family: $font-brand-bold;
    }
  }
}

.chatroom-modal {
  .inbox-user-list {
    border: 1px solid $grey-light;
    border-radius: $radius;
    max-height: 200px;
    padding: 0 0.25rem;
    overflow: scroll;
    margin-bottom: 0.5rem;
  }
  .settings-list {
    margin-bottom: 0.5rem;
  }
}
.inbox-new-header {
  display: flex;
  align-items: flex-start;
  .field.is-grouped {
    border: 2px solid darken($grey-dark, 5%);
    border-radius: $radius;
    padding: 0.25rem 0.25rem 0 0.25rem;
    flex-grow: 1;
    min-height: 2.5rem;
    .control {
      margin-bottom: 0.5rem !important;
    }
    .tag {
      margin-bottom: 0.25rem;
    }
  }
  .buttons {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 1rem;
  }
}

.alphabetical-results {
  margin-bottom: 0.5rem;
  span.heading {
    display: block;
    background: $light;
    padding: 0 0.25rem;
    font-weight: bold;
    font-size: 1rem;
  }
  a {
    padding: 0.5rem 0.25rem;
    border-bottom: 1px solid $light;
    font-family: $font-brand;
    color: $text;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
